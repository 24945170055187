import styled from "styled-components"

export const LuxurySection = styled.div`
  width: 100%;
  min-height: 100vh;
  background-image: url(${props => props.bgImage || "none"});
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.4);
  color: #fff;
  align-items: ${props => (props.flex ? "center" : "normal")};
  justify-content: ${props => (props.flex ? "center" : "normal")};
  display: ${props => (props.flex ? "flex" : "block")};
  flex-direction: ${props => (props.flex ? "column" : "row")};

  @media screen and (max-width: 768px) {
    background-position: 50%;
  }
`
