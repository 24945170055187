import styled from "styled-components"
import { LuxurySection } from "../luxury/luxuryComponents"

import { Button } from "../../styles/styled-component/Button"
export const ScenariosWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`
export const ScenariosTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`
export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 3rem;
  color: ${props => (props.colorGreen ? "#339960" : "inherit")};
`

export const ScenariosContainer = styled.div`
  background-color: #fff;
  padding: 32px;
  border-radius: 24px;
  width: 100%;
  box-shadow: 34px 34px 75px -24px rgb(56 50 124 / 8%);
  margin-bottom: 2rem;
`
export const ScenarioH3 = styled.h3`
  font-family: sans-serif;
  color: #339966;
  font-weight: 500;
`
export const SolarHero = styled(LuxurySection)`
  min-height: 80vh;

  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.7);
`
export const SpecsDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`
export const SpecsP = styled.p`
  font-weight: ${props => (props.title ? "700" : "500")};
  font-family: sans-serif;
  text-align: ${props => (props.title ? "left" : "right")};
`
export const ZeroButton = styled(Button)`
  color: #339966;
  background-color: white;
`
