import React from "react"

import { EndPageContainer } from "../../styles/styled-component/Container"
import Section from "../../styles/styled-component/Section"
import { Trans } from "gatsby-plugin-react-i18next"

import "../../styles/typography.css"
import { FaSolarPanel } from "react-icons/fa"
import { ZeroButton } from "./zeroElements"
const EndPage = () => {
  return (
    <Section flex bgColor="#339966">
      <EndPageContainer flex center direction="column" white>
        <FaSolarPanel size={124} />
        <h1 className="end-page-title">
          <Trans>Ready to go Green?</Trans>
        </h1>
        <p>
          <Trans>
            Fill a inquiry or book an appointment with our sales team
          </Trans>
        </p>
        <ZeroButton to="/contact" borderRadius="24px">
          <Trans>ASK US</Trans>
        </ZeroButton>
      </EndPageContainer>
    </Section>
  )
}

export default EndPage
