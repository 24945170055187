import React from "react"

import { GiPiggyBank, GiEcology } from "react-icons/gi"
import { IoVolumeMute } from "react-icons/io5"
import {
  IconContainer,
  IconP,
  Icon,
  IconWrapper,
  IconSection,
} from "../home/homeElements"
import { Trans } from "gatsby-plugin-react-i18next"
const IconRow = () => {
  return (
    <IconSection colorGreen>
      <IconWrapper flex>
        <IconContainer>
          <Icon>
            <GiPiggyBank />
          </Icon>
          <IconP>
            {" "}
            <Trans>Save Money</Trans>
          </IconP>
        </IconContainer>{" "}
        <IconContainer>
          <Icon>
            {" "}
            <GiEcology />
          </Icon>
          <IconP>
            <Trans>Green Solution</Trans>
          </IconP>
        </IconContainer>{" "}
        <IconContainer>
          <Icon>
            {" "}
            <IoVolumeMute />
          </Icon>{" "}
          <IconP>
            <Trans>Noiseless</Trans>
          </IconP>
        </IconContainer>
      </IconWrapper>
    </IconSection>
  )
}

export default IconRow
