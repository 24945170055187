import React from "react"
import Hero from "./hero"
import IconRow from "./IconRow"
import Scenarios from "./Scenarios"
import Specs from "./specs"
import EndPage from "./EndPage"
const ZeroPage = () => {
  return (
    <>
      <Hero />
      <IconRow />
      <Scenarios />
      <Specs />
      <EndPage />
    </>
  )
}

export default ZeroPage
