import React from "react"

import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ZeroPage from "../components/zero/zeroPage"
const ZeroEnergy = () => {
  return (
    <Layout>
      <Seo title="Zero energy Divas Lift" />
      <ZeroPage />
    </Layout>
  )
}

export default ZeroEnergy

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
