import React from "react"
import Container from "../../styles/styled-component/Container"
import InnerContainer from "../../styles/styled-component/InnerContainer"
import Section from "../../styles/styled-component/Section"
import { Trans } from "gatsby-plugin-react-i18next"
import { ScenariosContainer, SpecsDiv, SpecsP } from "./zeroElements"
const Specs = () => {
  let specsTitles = [
    <Trans>Drive</Trans>,
    <Trans>Power</Trans>,
    <Trans>Power Supply</Trans>,
    <Trans>Power Consumption</Trans>,
    <Trans>Rated Capacity</Trans>,
    <Trans>Travel</Trans>,
    <Trans>Speed</Trans>,
    <Trans>Stops</Trans>,
    <Trans>Access</Trans>,
    <Trans>Intermittance</Trans>,
  ]
  let specsValue = [
    <Trans>Gearless</Trans>,

    <Trans>Up to 3Kw</Trans>,

    <Trans>220V AC - Single Phase</Trans>,

    <Trans>300W</Trans>,

    <Trans>325 / 400 / 480 KG</Trans>,

    <Trans>Up to 18 Meters</Trans>,

    <Trans>0.3 m/s</Trans>,

    <Trans>Up to 6</Trans>,

    <Trans>12</Trans>,

    <Trans>30%</Trans>,
  ]
  return (
    <Section flex center bgColor="#f7f8fc">
      <Container>
        <InnerContainer>
          <h2>
            <Trans>Tech data and specifications</Trans>
          </h2>
        </InnerContainer>{" "}
      </Container>
      <Container flex center smallMaxWidth>
        <ScenariosContainer>
          <ul>
            {specsTitles.map((title, index) => (
              <SpecsDiv>
                {" "}
                <SpecsP key={index} title>
                  {title}:
                </SpecsP>
                <SpecsP key={index}> {specsValue[index]}</SpecsP>
              </SpecsDiv>
            ))}
          </ul>
        </ScenariosContainer>
      </Container>
    </Section>
  )
}

export default Specs
