import styled from "styled-components"
import Button from "../../styles/styled-component/Button"
import Container from "../../styles/styled-component/Container"
export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #fff;
  padding: 1rem;
  margin: 1rem 0;
  background-color: ${props => (props.colorGreen ? "#339960" : "#1a5090")};
  width: 100%;
  &:nth-child(2) {
    color: #fff;
    margin: 0;
    padding: 2rem;
    background-color: #339960;
  }
  @media screen and (max-width: 640px) {
    flex-direction: column;
    align-items: center;
  }
`

export const IconP = styled.p`
  font-size: 1.2rem;
  text-align: center;
`
export const Icon = styled.p`
  font-size: 3rem;
`

export const IconWrapper = styled(Container)`
  display: flex;
  align-items: flex-end;
  justify-content: space-evenly;
  flex-direction: row;
  border-radius: 0;

  @media screen and (max-width: 640px) {
    flex-direction: column;
    align-items: center;
  }
`
export const IconSection = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #1a5090;
  @media screen and (max-width: 640px) {
    flex-direction: column;
  }
`
export const IconButton = styled(Button)`
  border: 1px solid #fff;
`
