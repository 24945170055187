import React from "react"
import Container from "../../styles/styled-component/Container"
import InnerContainer from "../../styles/styled-component/InnerContainer"
import Section from "../../styles/styled-component/Section"
import { BsBatteryCharging, BsBatteryFull, BsBattery } from "react-icons/bs"
import { MdPower, MdPowerOff } from "react-icons/md"
import {
  ScenariosTextContainer,
  ScenariosWrapper,
  IconContainer,
  ScenariosContainer,
  ScenarioH3,
} from "./zeroElements"

import { Trans } from "gatsby-plugin-react-i18next"
const Scenarios = () => {
  return (
    <>
      <Section flex center minHeight>
        <Container>
          <InnerContainer>
            <h2>
              <Trans>How it works</Trans>
            </h2>
            <p>
              <Trans>
                If your building has the space needed to install two solar
                panels ( 2m x 3,40m ), Zero Energy system is the green solution
                to power your elevator, when functional the batteries will be
                charged by the solar panels, saving on the energy bill and
                helping reduce energy waste achieving a more sustainable
                solution.
              </Trans>
            </p>
            <p>
              <Trans>
                Zero Energy is a system developed by Movilift to reduce energy
                cost for your residential elevator, working on the latest
                traction and Permanent Magnets motors, it uses two batteries to
                reduce the usage of the power grid, a standby system to reduce
                energy waste, and the possibility to have speed variation to
                optimize the total energy cost.
              </Trans>{" "}
            </p>
          </InnerContainer>
        </Container>
      </Section>
      <Section flex center bgColor="#f7f8fc">
        <Container>
          <InnerContainer>
            <h2>
              <Trans>Saving energy, always operational</Trans>
            </h2>
            <p>
              <Trans>
                Learn how we made Zero Energy work in different scenarios to
                guarantee the user with full-time operation.
              </Trans>
            </p>
          </InnerContainer>{" "}
        </Container>
        <Container flex center smallMaxWidth>
          <ScenariosContainer>
            <ScenariosWrapper>
              <IconContainer colorGreen>
                <MdPower /> <BsBatteryFull rotate={90} />
              </IconContainer>{" "}
              <ScenariosTextContainer>
                <ScenarioH3>
                  <Trans>Power on and batteries full</Trans>
                </ScenarioH3>
                <p>
                  <Trans>
                    When power grid is functional and batteries are working, the
                    elevator will operate using battery mode
                  </Trans>
                </p>
              </ScenariosTextContainer>
            </ScenariosWrapper>
            <ScenariosWrapper>
              <IconContainer colorGreen>
                <MdPower /> <BsBatteryCharging rotate={90} />
              </IconContainer>{" "}
              <ScenariosTextContainer>
                <ScenarioH3>
                  <Trans>Power on and batteries depleted</Trans>
                </ScenarioH3>
                <p>
                  <Trans>
                    {" "}
                    Once batteries have been depleted, the elevator will operate
                    using the 220V until batteries have been fully charged
                  </Trans>
                </p>
              </ScenariosTextContainer>
            </ScenariosWrapper>
            <ScenariosWrapper>
              <IconContainer colorGreen>
                <MdPowerOff /> <BsBatteryFull rotate={90} />
              </IconContainer>{" "}
              <ScenariosTextContainer>
                <ScenarioH3>
                  <Trans>Power off and batteries working</Trans>
                </ScenarioH3>
                <p>
                  <Trans>
                    If the power grid is down, the elevator will operate using
                    batteries for a specific amount of rides (based on the
                    elevator specifications)
                  </Trans>
                </p>
              </ScenariosTextContainer>
            </ScenariosWrapper>
            <ScenariosWrapper>
              <IconContainer colorGreen>
                <MdPowerOff /> <BsBattery rotate={90} />
              </IconContainer>{" "}
              <ScenariosTextContainer>
                <ScenarioH3>
                  <Trans>Power off and batteries depleted</Trans>
                </ScenarioH3>
                <p>
                  <Trans>
                    If the power goes out and batteries are depleted, the UPS
                    energy mode will power the elevator.
                  </Trans>
                </p>
              </ScenariosTextContainer>
            </ScenariosWrapper>
          </ScenariosContainer>
        </Container>
      </Section>
    </>
  )
}

export default Scenarios
