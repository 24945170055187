import React from "react"
import Container from "../../styles/styled-component/Container"
import InnerContainer from "../../styles/styled-component/InnerContainer"
import { Trans } from "gatsby-plugin-react-i18next"
import bgImage from "../../images/solarHomeB.jpeg"
import { GiSolarPower } from "react-icons/gi"
import { SolarHero } from "./zeroElements"
const Hero = () => {
  return (
    <SolarHero flex bgImage={bgImage}>
      <Container>
        <InnerContainer>
          <h1>
            <Trans>Zero Energy Divas Lift</Trans>{" "}
            <span>
              <GiSolarPower />
            </span>
          </h1>
          <p>
            <Trans>Renewable energy powered home lift made in italy</Trans>
          </p>
        </InnerContainer>
      </Container>{" "}
    </SolarHero>
  )
}

export default Hero
